import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { PageLink } from '../..'
import { l } from '../../../lib/locale'
import { pageURL } from '../../../lib/page'
import { context } from '../../../store'
import { campaigns } from '../../../config/campaigns.json'
import Image from './image'

import './hero.scss'

const ContentBlockHomePageHero = ({ lang }) => {
  const { store } = useContext(context)

  let banner = null, campaign = null
  if (store && store.app && store.app.cartrules) {
    const cartrules = store.app.cartrules || null

    if (campaigns && campaigns.length > 0 && cartrules && cartrules.length > 0) {
      cartrules.forEach(rule => {
        const c = campaigns.find(c => c.code === rule.code || c.code === rule.display_code)
        if (c && c.priority) {
          campaign = c
          banner = campaign.banner
        }
      })
    }
  }

  if (banner && banner.hero && banner.hero.image) {
    return (
      <div className="hero row no-gutters mt-3 banner">
        <div className="col-12 col-md-8 col-lg-9">
          <img src={banner.hero.source} className="img-fluid" alt={l(banner.hero.title, lang)} />
        </div>
        <div className="col-12 col-md-4 col-lg-3">
          <div className="hero-copy px-5 my-4 my-md-0">
            <h1>{l(banner.hero.title, lang)}</h1>
            <p dangerouslySetInnerHTML={{ __html: l(banner.hero.paragraph, lang) }} />
            {banner.hero.page && (
              <PageLink to={`${pageURL(banner.hero.page, lang)}`} className="btn-large-secondary">
                {l(banner.hero.button, lang)}
              </PageLink>
            )}
          </div>
        </div>
      </div>
    )
  }

  const link = lang.id_lang === 4 ? '/nl/zoeken?q=hay%20palissade' : '/en/search?q=hay%20palissade'
  // const link = lang.id_lang === 4 ? '/nl/merken/136-HOUE' : '/en/brands/136-HOUE'
  // const link = pageURL('upnorth5y', lang)
  // const link = null

  // <h1 className="">{l('block-hero-title', lang)}</h1>
  // <p dangerouslySetInnerHTML={{ __html: l('block-hero-paragraph', lang) }} />
  // <PageLink to={link} className="btn-large-secondary mb-5 btn-blackx float-right">
  //           {l('block-hero-button', lang)}
  //         </PageLink>

  return (
    <div className="hero row no-gutters mt-3 xx" style={{ backgroundColor: "rgb(182,117,97)" }}>
      <div className="col-12">
        <a href={link} className="">
          <Image className="hero-image" alt={l('block-hero-alt', lang)} lang={lang} />
        </a>
      </div>
      {link && (
        <div className="col-6 offset-6" style={{ position: "absolute", right: "1%", top: "1%", backgroundColor: "" }}>
          <h1 className="">{l('block-hero-title', lang)}</h1>
          <p dangerouslySetInnerHTML={{ __html: l('block-hero-paragraph', lang) }} />
        </div>
      )}
    </div>
  )
}

ContentBlockHomePageHero.propTypes = {
  lang: PropTypes.object
}

export default ContentBlockHomePageHero
