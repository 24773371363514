import React from 'react';
import PropTypes from 'prop-types'

import { l } from '../../../lib/locale';


const CheckoutProgressSteps = ({ lang, step }) => {
    const getClass = (stepNumber) => {
        if (stepNumber === step) {
            return 'step-active'
        } else if (stepNumber < step) {
            return 'step-completed'
        }
        return '';
    };
    return (
        <div className="content">
            <div className={`checkout-progress-wrapper grid-4`}>
                <div className={`checkout-progress-step ${getClass(1)}`}>
                  <div className='checkout-progress-step-number'>1</div>
                  <div className='checkout-progress-step-label'>{l('checkout-info', lang)}</div>
                </div>
                <div className={`checkout-progress-step ${getClass(2)}`}>
                  <div className='checkout-progress-step-number'>{2}</div>
                  <div className='checkout-progress-step-label'>{l('checkout-shipping', lang)}</div>
                </div>
                <div className={`checkout-progress-step ${getClass(3)}`}>
                  <div className='checkout-progress-step-number'>{3}</div>
                  <div className='checkout-progress-step-label'>{l('checkout-giftwrapping', lang)}</div>
                </div>
                <div className={`checkout-progress-step ${getClass(4)}`}>
                  <div className='checkout-progress-step-number'>{4}</div>
                  <div className='checkout-progress-step-label'>{l('checkout-payment', lang)}</div>
                </div>
            </div>
        </div>
    )
};

CheckoutProgressSteps.propTypes = {
    isGuest: PropTypes.bool,
    lang: PropTypes.object,
    step: PropTypes.number
}

export default CheckoutProgressSteps;