import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { CheckoutBlockContact, CheckoutProgressSteps, CheckoutBlockShipping, CheckoutBlockGiftWrapping, PageLink,
  CheckoutBlockPayment, CheckoutBlockGuest, SKUAvailabilityLabel } from '../..'
import { context } from '../../../store'
import { l, t } from '../../../lib/locale'

const { pages } = require('../../../config/pages.json')
const cartPage = pages.find(i => i.id === 'cart')

const CheckoutStep = ({ lang, isInfo, isShipping, isGiftWrapping, isPayment }) => {
  const { store, cartProvider } = useContext(context)

  const { cart, checkout } = store && store.cart
    ? store.cart
    : { cart: null }

  if (!checkout) {
    return ''
  }

  if (!cart || (cart && !cart._embedded) || (cart && cart._embedded && !cart._embedded.items)) {
    navigate(cartPage [`path_${lang.suffix}`]);
  }

  const getStep = () => {
    if (isInfo) {
      return 1
    } else if (isShipping) {
      return 2
    } else if (isGiftWrapping) {
      return 3
    } else if (isPayment) {
      return 4
    }
  }

  return (
    <div className="row">
        <div className="col-12">
          <CheckoutProgressSteps lang={lang} step={getStep()}/>
        </div>
      <div className="col-12 col-md-8">
        <CheckoutBlockContact lang={lang} active={isInfo} index={1} store={store} cartProvider={cartProvider} />
        <CheckoutBlockShipping lang={lang} active={isShipping} index={2} store={store} cartProvider={cartProvider} />
        <CheckoutBlockGiftWrapping lang={lang} active={isGiftWrapping} index={3} store={store} cartProvider={cartProvider} />
        <CheckoutBlockPayment lang={lang} active={isPayment} index={4} store={store} cartProvider={cartProvider} />
      </div>

      <div className="extra-info col-md-4">
        <div className="row">
          <div className="ex-info-cart ex-info-table">
            <div className="ov-header d-flex align-items-center justify-content-between">
              <div>
                <h3>{l('checkout-cart', lang)}</h3>
              </div>
              <PageLink to={cartPage[`path_${lang.suffix}`]} className="btn btn-link">
                {l('button-edit', lang)}
              </PageLink>
            </div>
            <div className="ov-details">
              <h4 className="d-none">{l('checkout-delivery-on', lang)} ...</h4>

              {cart._embedded && cart._embedded.items && cart._embedded.items.map(item => (
                <div className="d-flex" key={`ov-details-${item.id}`}>
                  <div>
                    <img
                      src={`${process.env.GATSBY_CDN_URL}/p/${item.id_default_image.toString().split('').join('/')}/${item.id_default_image}-large_default.jpg`}
                      alt={item[`name_${lang.suffix}`]} />
                  </div>
                  <div className="d-flex flex-column">
                    <h5>{t(item, 'name', lang)}{item.combination ? ` - ${item.combination}` : ''} <span className="badge badge-secondary ml-1">{item.quantity}</span></h5>
                    <p>{item.manufacturer_hidden ? "" : item.manufacturer_name}</p>
                    <p>
                      &euro; {Number(item.sellPrice_tax_incl).toFixed(2)}
                      {+item.applied_reduction_quantity > 0 ? (
                        <span className="badge badge-secondary ml-1">{item.required_reduction_quantity}+{item.applied_reduction_quantity}</span>
                      ) : +item.applied_reduction_amount > 0 ? (
                        <span className="badge badge-secondary ml-1">-{Number(item.applied_reduction_percent).toFixed(2)}&euro;</span>
                      ) : +item.applied_reduction_percent > 0 ? (
                        <span className="badge badge-secondary ml-1">-{Number(item.applied_reduction_percent).toFixed(0)}%</span>
                      ) : ''}
                    </p>
                    <SKUAvailabilityLabel sku={item} lang={lang} />
                  </div>
                </div>
              ))}

              {l('msg-webshop-attention', lang) && (
                <div className="alert alert-warning mb-0">
                  {l('msg-webshop-attention', lang)}
                </div>
              )}

            </div>
          </div>
        </div>
        <div className="row">
          <div className="ex-info-table ex-info-overview">
            <div className="ov-header d-flex align-items-center">
              <h3>{l('checkout-overview', lang)}</h3>
            </div>
            <div className="ov-details">
              <div className="d-flex justify-content-between">
                <div>
                  <p>{l('cart-subtotal', lang)}</p>
                </div>
                <div>
                  <p>&euro; {Number(cart.totals.total_products_wt).toFixed(2)}</p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p>{l('cart-shipping', lang)}</p>
                </div>
                <div>
                  {cart.totals.total_shipping_tax_incl > 0 ? (
                    <p>&euro; {Number(cart.totals.total_shipping_tax_incl).toFixed(2)}</p>
                  ) : (
                    <p>{l('free', lang)}</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p>{l('cart-discount', lang)}</p>
                </div>
                <div>
                  <p>- &euro; {Number(cart.totals.total_discounts_tax_incl).toFixed(2)}</p>
                </div>
              </div>

              {cart && cart._embedded && cart._embedded.rules ? (
                <>
                  <hr />
                  {cart._embedded.rules.map(rule => (
                    <div className="d-flex justify-content-between" key={`cart-rule-${rule.id_cart_rule}`}>
                      <div>
                        <p>{rule[`description_${lang.suffix}`] || rule.code || rule[`name_${lang.suffix}`]}</p>
                      </div>
                      <div>
                        {rule.computed_amount ? (
                          <p className="pt-1">- &euro; {Number(rule.computed_amount).toFixed(2)}</p>
                        ) : rule.reduction_amount ? (
                          <p className="pt-1">- &euro; {Number(rule.reduction_amount).toFixed(2)}</p>
                        ) : rule.reduction_percent ? (
                          <p className="pt-1">- {Number(rule.reduction_percent).toFixed(0)}%</p>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
              <hr />
              <div className="d-flex justify-content-between">
                <div>
                  <p>{l('cart-vat', lang)}</p>
                </div>
                <div>
                  <p>&euro; {Number(cart.totals.total_vat).toFixed(2)}</p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p>{l('cart-total-incl-vat', lang)}</p>
                </div>
                <div>
                  <p>&euro; {Number(cart.totals.total_paid_tax_incl).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CheckoutStep.propTypes = {
  lang: PropTypes.object,
  isInfo: PropTypes.bool,
  isShipping: PropTypes.bool,
  isGiftWrapping: PropTypes.bool,
  isPayment: PropTypes.bool
}

export default CheckoutStep
